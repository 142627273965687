import { ICookieContext } from '@msdyn365-commerce/core-internal/dist/types';
const FEATURE_COOKIE_KEY = 'features';

export const getFeatureToggleService = (cookieContext: ICookieContext, serverFeatures: string[]) => {
    const getFeaturesCookie = (cookieContext: ICookieContext) => cookieContext.get<string>(FEATURE_COOKIE_KEY);

    const getEnabledFeatures = (): string[] => {
        const featuresCookie = getFeaturesCookie(cookieContext).value;
        const clientFeatures = featuresCookie ? featuresCookie.split(',').map(feature => feature.trim()) : [];
        return clientFeatures.concat(serverFeatures);
    };

    const isFeatureEnabled = (featureName: string): boolean => getEnabledFeatures().includes(featureName);
    return {
        getEnabledFeatures,
        isFeatureEnabled
    };
};
