import React, { useState } from 'react';
import classnames from 'classnames';

import { Text } from '@msdyn365-commerce/core';
import { Button, format } from '@msdyn365-commerce-modules/utilities';
import { updateCustomerAttributes, updateCustomerAttributesInput } from '@msdyn365-commerce-modules/retail-actions';
import isEqual from 'lodash/isEqual';

import { IFmAccountProfileData } from '../fm-account-profile.data';
import { IFmAccountProfileProps } from '../fm-account-profile.props.autogenerated';
import TextComponent from './fm-account-profile-text';
import { updateAllConsentsAsync } from '../../../actions/DataActionExtension.g';

export interface ProfileConsent {
    text: string; // GenMarketing, Profiling, ThirdParty
    value: boolean;
}

export enum ConsentText {
    GEN_MARKETING = 'GenMarketing',
    PROFILING = 'Profiling',
    THIRD_PARTY = 'ThirdParty'
}

export enum ConsentName {
    EMAIL = 'Email',
    SMS = 'Sms'
}

interface ConsentChannel {
    title: string; // Marketing telefono | Marketing Email
    text: string; // Numbero telefono | Indirizzo Email
    name: ConsentName; // Sms | Email
    value: string; // +3935165656 | test@venchi.com
    emailConsents: ProfileConsent[];
    smsConsents: ProfileConsent[];
}

interface IAccountProfileAttributesProps {
    accountProfileProps: IFmAccountProfileProps<IFmAccountProfileData>;
}

interface ProfileConsentSectionProps {
    consentChannel: ConsentChannel;
    locale: string;
}

const ProfileConsentSection = (props: IAccountProfileAttributesProps & ProfileConsentSectionProps) => {
    let initialChannelValue = props.consentChannel.value;
    let initialConsents =
        props.consentChannel.name === ConsentName.SMS ? props.consentChannel.smsConsents : props.consentChannel.emailConsents;

    const MAX_PHONE_LENGTH = 25;

    const [consents, setConsents] = useState<ProfileConsent[]>(initialConsents);

    const [channelValue, setChannelValue] = useState<string>(initialChannelValue);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<string>('');
    const [gotExceptionError, setGotExceptionError] = useState<boolean>(false);

    const handleAttributeChange = (event: any) => {
        setChannelValue(event.target.value);
    };

    const handleConsentToggle = (consent: ProfileConsent) => {
        setConsents(prevConsents => prevConsents.map(c => (c.text === consent.text ? { ...c, value: !c.value } : c)));
    };

    const handleSaveButton = async () => {
        const { context, data, resources } = props.accountProfileProps;
        const customer = data.customerInformation.result;

        if (!customer || !context) {
            return;
        }

        const promises: Promise<any>[] = [];

        if (props.consentChannel.name === ConsentName.SMS) {
            if (channelValue.trim().length > MAX_PHONE_LENGTH) {
                setValidationError(format(resources.attributeInputStringMaxLengthErrorText, 'Phone', MAX_PHONE_LENGTH));
            } else {
                customer.Phone = channelValue.trim();

                const input = new updateCustomerAttributesInput(
                    customer.AccountNumber,
                    customer.Attributes || [],
                    context.request.apiSettings,
                    customer.Phone,
                    customer.VatNumber
                );

                if (customer.Phone !== initialChannelValue) {
                    promises.push(
                        updateCustomerAttributes(input, context.actionContext).then(() => {
                            initialChannelValue = channelValue;
                        })
                    );
                }
            }
        }

        if (!isEqual(consents, initialConsents)) {
            const updatedConsents = {
                key: 0,
                ...(props.consentChannel.name === ConsentName.SMS
                    ? {
                          GenMarketingSms: consents.find(c => c.text === ConsentText.GEN_MARKETING)?.value,
                          ProfilingSms: consents.find(c => c.text === ConsentText.PROFILING)?.value,
                          ThirdPartySms: consents.find(c => c.text === ConsentText.THIRD_PARTY)?.value,
                          GenMarketingEmail: props.consentChannel.emailConsents.find(c => c.text === ConsentText.GEN_MARKETING)?.value,
                          ProfilingEmail: props.consentChannel.emailConsents.find(c => c.text === ConsentText.PROFILING)?.value,
                          ThirdPartyEmail: props.consentChannel.emailConsents.find(c => c.text === ConsentText.THIRD_PARTY)?.value
                      }
                    : {
                          GenMarketingEmail: consents.find(c => c.text === ConsentText.GEN_MARKETING)?.value,
                          ProfilingEmail: consents.find(c => c.text === ConsentText.PROFILING)?.value,
                          ThirdPartyEmail: consents.find(c => c.text === ConsentText.THIRD_PARTY)?.value,
                          GenMarketingSms: props.consentChannel.smsConsents.find(c => c.text === ConsentText.GEN_MARKETING)?.value,
                          ProfilingSms: props.consentChannel.smsConsents.find(c => c.text === ConsentText.PROFILING)?.value,
                          ThirdPartySms: props.consentChannel.smsConsents.find(c => c.text === ConsentText.THIRD_PARTY)?.value
                      })
            };
            promises.push(updateAllConsentsAsync({ callerContext: context.actionContext }, updatedConsents));
        }

        if (!promises.length) {
            return;
        }

        setIsUpdating(true);
        setCanEdit(false);

        try {
            await Promise.all(promises);
            initialConsents = consents;
        } catch (error) {
            setGotExceptionError(true);

            if (context.telemetry) {
                context.telemetry.exception(error as Error);
                context.telemetry.debug('Unable to update customer attributes');
            }

            setChannelValue(initialChannelValue);
        }

        setIsUpdating(false);
    };

    const handleCancelButton = () => {
        setConsents(initialConsents);
        setCanEdit(false);
        setChannelValue(initialChannelValue);
    };

    const handleEditButton = () => {
        setGotExceptionError(false);
        setValidationError('');
        setCanEdit(true);
    };

    const renderConsentRow = (consent: ProfileConsent): JSX.Element | null => {
        const value = consent.value;
        const toggleState = value ? 'enable' : 'disable';
        const ariaLabel = format(props.accountProfileProps.resources.attributeToggleButtonAriaLabel, consent.text);

        const attributeClassName = 'ms-account-profile__attributes-element';

        let text;

        switch (consent.text) {
            case ConsentText.GEN_MARKETING:
                text =
                    props.locale.toLowerCase() !== 'en-us'
                        ? format(
                              props.accountProfileProps.resources.htmlGenericMarketing,
                              props.accountProfileProps.config.privacyPolicyURL
                          )
                        : props.accountProfileProps.config.textGenericMarketing;
                break;
            case ConsentText.PROFILING:
                text = format(props.accountProfileProps.resources.htmlProfiling, props.accountProfileProps.config.privacyPolicyURL);
                break;
            case ConsentText.THIRD_PARTY:
                text = format(props.accountProfileProps.resources.htmlThirdParty, props.accountProfileProps.config.privacyPolicyURL);
                break;
        }

        return (
            <div className={classnames('ms-account-profile__attributes__section', 'ms-account-profile__attributes__section__toggle')}>
                <div dangerouslySetInnerHTML={{ __html: String(text) }} />

                <div
                    className={`ms-account-profile__attributes__section__toggle-wrapper ms-account-profile__attributes__section__toggle-${toggleState}`}
                >
                    <TextComponent
                        className='ms-account-profile__attributes__section__toggle-disable-text'
                        text={props.accountProfileProps.resources.toggleDisableText}
                    />
                    <Button
                        className={classnames(
                            attributeClassName,
                            'ms-account-profile__attributes__section__toggle-button',
                            `ms-account-profile__attributes__section__toggle-${toggleState}-button`
                        )}
                        aria-label={`${ariaLabel}`}
                        aria-pressed={value}
                        value={String(value)}
                        onClick={() => handleConsentToggle(consent)}
                        disabled={!canEdit}
                    />
                    <TextComponent
                        className='ms-account-profile__attributes__section__toggle-enable-text'
                        text={props.accountProfileProps.resources.toggleEnableText}
                    />
                </div>
            </div>
        );
    };

    const editAsyncCustomerFeatureName = 'Dynamics.AX.Application.RetailEnableAsyncCustomerEditFeature';

    const customerInformation = props.accountProfileProps.data.customerInformation.result;
    const isEditAsyncCustomerFeatureEnabled =
        props.accountProfileProps.data.featureState?.result?.find(feature => feature.Name === editAsyncCustomerFeatureName)?.IsEnabled ||
        false;

    // Disable the 'Edit' button if the switch is enabled and the customer is async.
    const disableBtn: boolean =
        (props.accountProfileProps.context.app?.config?.canRenderAsyncCustomerDataAsUnmodifiable || false) &&
        (customerInformation?.IsAsyncCustomer || false) &&
        !isEditAsyncCustomerFeatureEnabled;

    const additionalInformationSectionHeading =
        (props.accountProfileProps.config.additionalInformationSectionHeading &&
            props.accountProfileProps.config.additionalInformationSectionHeading.text) ||
        '';

    return (
        <div
            className={classnames('ms-account-profile__attributes', {
                'ms-account-profile__attributes-updating': isUpdating
            })}
        >
            <Text className='ms-account-profile__attributes-tile__heading' tag={'h3'} text={props.consentChannel.title} />

            <div className={classnames('ms-account-profile__attributes__section')}>
                <Text className='ms-account-profile__attributes__section-heading' tag='h3' text={props.consentChannel.text} />
                <input
                    type='text'
                    aria-label={props.consentChannel.text}
                    className={classnames('ms-account-profile__attributes_input', `ms-account-profile__attributes_input-edit-${canEdit}`)}
                    onChange={handleAttributeChange}
                    value={channelValue}
                    disabled={props.consentChannel.name === ConsentName.EMAIL || !canEdit}
                />
                {validationError && (
                    <div className='ms-account-profile__attributes-error' role='alert' aria-live='assertive'>
                        <label className='ms-account-profile__attributes-error-label'>{validationError}</label>
                    </div>
                )}
            </div>

            {consents.map(renderConsentRow)}

            {!canEdit && (
                <Button
                    className={classnames('ms-account-profile__attributes-edit-button', {
                        // Disable the toggle completely if customer is async.
                        'ms-account-profile__attributes-edit-button-disabled': disableBtn
                    })}
                    aria-label={`${props.accountProfileProps.resources.attributesEditButtonText} ${additionalInformationSectionHeading}`}
                    onClick={handleEditButton}
                    title={props.accountProfileProps.resources.attributesEditButtonText}
                    disabled={disableBtn}
                >
                    {props.accountProfileProps.resources.attributesEditButtonText}
                </Button>
            )}
            {gotExceptionError && (
                <div className='ms-account-profile__attributes-error'>
                    <label className='ms-account-profile__attributes-error-label'>
                        {props.accountProfileProps.resources.attributesSaveExceptionMessage}
                    </label>
                </div>
            )}
            {canEdit && (
                <Button
                    className={classnames('ms-account-profile__attributes-save-button')}
                    aria-label={props.accountProfileProps.resources.attributesSaveButtonText}
                    onClick={handleSaveButton}
                    title={props.accountProfileProps.resources.attributesSaveButtonText}
                >
                    {props.accountProfileProps.resources.attributesSaveButtonText}
                </Button>
            )}
            {canEdit && (
                <Button
                    className={classnames('ms-account-profile__attributes-cancel-button')}
                    aria-label={props.accountProfileProps.resources.attributesCancelButtonText}
                    onClick={handleCancelButton}
                    title={props.accountProfileProps.resources.attributesCancelButtonText}
                >
                    {props.accountProfileProps.resources.attributesCancelButtonText}
                </Button>
            )}
        </div>
    );
};

export default ProfileConsentSection;
